import "twin.macro"
import React, { FC } from "react"
import { RiMenu4Line } from "react-icons/ri"
import { Link } from "gatsby"

type ButtonProps = {
  menu?: boolean
  onClick?: () => void
  to: string
}

const Button: FC<ButtonProps> = ({ menu, children, onClick }) => {
  return (
    <>
      <button onClick={onClick} tw="relative whitespace-nowrap block  ">
        <span tw="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-black rounded"></span>
        <span tw="relative inline-block w-full h-full px-3 py-1 text-base font-bold transition duration-100 bg-white border-2 border-black rounded  hover:bg-yellow-400 hover:text-gray-900">
          <div tw="font-medium  flex space-x-1 justify-center items-center mr-1">
            <p>{children}</p>
            {menu && <RiMenu4Line />}
          </div>
        </span>
      </button>
    </>
  )
}
export const ButtonLink: FC<ButtonProps> = ({
  menu,
  children,
  onClick,
  to,
}) => {
  return (
    <>
      <Link to={to} onClick={onClick} tw="relative block text-black">
        <span tw="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-black rounded"></span>
        <span tw="relative inline-block w-full h-full px-3 py-1 text-base font-bold transition duration-100 bg-white border-2 border-black rounded  hover:bg-yellow-400 hover:text-gray-900">
          <div tw="font-medium  flex space-x-1 items-center mr-1">
            <p>{children}</p>
            {menu && <RiMenu4Line />}
          </div>
        </span>
      </Link>
    </>
  )
}

export default Button
