import "twin.macro"
import React from "react"
import Button from "./Button"

const CallCard = () => {
  return (
    <div tw="p-5">
      <div tw="px-5 py-10 shadow-2xl rounded-2xl space-y-2 max-w-5xl mx-auto my-10">
        <div tw="space-y-2 md:(flex space-x-2 space-y-0)">
          <p tw="text-xl md:text-3xl text-yellow-400 text-center md:text-left font-bold">
            Would you like to get your Dream fullfilled with CareerTherapies?
          </p>
          <div tw="flex justify-center md:justify-start items-start">
            <Button to="#">+91-9034495205</Button>
          </div>
        </div>

        <div tw="space-y-2">
          <p tw="font-black text-center ">
            Free Resume Review Timings: 9 AM- 10 AM (Monday-Friday)
          </p>
          <p>
            If you have any kind of queries and question then you can contact us
            on this phone number. We look forward to be working with you.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CallCard
