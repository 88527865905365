import * as React from "react"
import { GlobalStyles } from "twin.macro"
import Footer from "./Footer"
import Navbar from "./Navbar"
import "twin.macro"
import CallCard from "./ui/CallCard"

const Layout: React.FC = ({ children }) => {
  return (
    <div>
      <Navbar />
      <GlobalStyles />
      <main>{children}</main>
      <CallCard />
      <Footer />
    </div>
  )
}

export default Layout
