import tw from "twin.macro"
import React, { useState } from "react"
import { Link } from "gatsby"
import { RiMenu4Line } from "react-icons/ri"
import Button, { ButtonLink } from "../components/ui/Button"

export const NavbarData = [
  {
    name: "Home",
    icon: "",
    to: "/",
  },
  {
    name: "Services",
    icon: "",
    to: "/services",
  },

  {
    name: "Blog",
    icon: "",
    to: "/blog",
  },
  {
    name: "About",
    icon: "",
    to: "/about",
  },
  {
    name: "Contact",
    icon: "",
    to: "/contact",
  },
]

const Navbar = () => {
  const [changenavbar, setchangenavbar] = useState(false)

  function changebackground() {
    if (typeof window !== "undefined") {
      if (window.scrollY >= 10) setchangenavbar(true)
      else setchangenavbar(false)
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", changebackground)
  }
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <nav tw="fixed z-10 w-full bg-white" css={[changenavbar && tw`shadow`]}>
        <div tw=" space-y-3 md:(space-y-0) mx-auto px-3 py-4 max-w-7xl md:(flex justify-between items-center p-5)">
          <div tw="flex justify-between   ">
            <h2 tw="relative      text-2xl mx-1  md:text-4xl font-black">
              <span tw="absolute  w-full h-4 mt-3 -ml-2 bg-yellow-300"></span>
              <span tw="relative">CareerTherapies</span>
            </h2>
            <div tw=" md:hidden">
              <Button to="#" onClick={() => setIsOpen(!isOpen)} menu>
                Menu
              </Button>
            </div>
          </div>

          <div
            css={
              isOpen
                ? tw` transition-all left-0`
                : tw` transition-all -left-full`
            }
            tw="fixed space-y-5  w-full h-full flex flex-col items-center top-12 pt-8  bg-black text-white   md:(pt-0  text-black space-y-0 static bg-transparent w-auto h-auto flex-row space-x-4) "
          >
            {NavbarData.map((navitem, index) => (
              <Link
                key={index}
                tw="font-bold block md:inline  transition-all px-1 py-2 duration-500 background[linear-gradient(rgba(252, 211, 77, var(--tw-bg-opacity)) 0 0) 0 100% /var(--d, 0) 3px no-repeat]  hover:(--d[100%])"
                to={navitem.to}
              >
                {navitem.name}
              </Link>
            ))}

            <ButtonLink to="/live-workshop">
              Live Workshop - Coming Soon
            </ButtonLink>
          </div>
        </div>
      </nav>

      <div tw="min-height[10vh]"></div>
    </>
  )
}

export default Navbar
